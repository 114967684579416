<template>
  <core-section id="who-i-am">
    <abstract1 />

    <core-heading>Wer wir sind!</core-heading>

    <v-col
      cols="12"
      md="7"
    >
      <v-row justify="space-between">
        <v-col
          cols="12"
          md="7"
        >
          <core-text class="mb-0" style="z-index: 99 !important;">
            <p v-text="schema.basics.summary" style="z-index: 99 !important;" />
            <p v-text="schema.basics.summary2" style="z-index: 99 !important;" />
          </core-text>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-row align="center">
            <v-col
              cols="6"
              class="text-uppercase primary--text font-weight-medium"
            >
              <div
                class="mb-3"
                v-text="'Sitz'"
              />
              <div
                class="mb-3"
                v-text="'Email'"
              />
              <div
                class="mb-3"
                v-text="'Telefon'"
              />
            </v-col>
            <v-col
              cols="6"
              class="subheading text-no-wrap"
            >
              <div
                class="mb-3"
                v-text="'Deutschland'"
              />
              <div
                class="mb-3"
                v-text="schema.basics.email"
              />
              <div
                v-text="schema.basics.phone"
              />
              <div
                v-text="schema.basics.phonemobil"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      class="hidden-sm-and-down text-right text-uppercase"
      cols="5"
    >
      <div
        class="display-1"
        v-text="schema.basics.label"
      />

      <span
        class="primary--text headline font-weight-light"
        v-text="schema.basics.name"
      />

    </v-col>
  </core-section>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'WhoIAm',

    components: {
      Abstract1: () => import('@/components/Abstract1'),
    },

    computed: {
      ...mapState('app', ['schema']),
    },
  }
</script>

<style lang="sass">
  .v-expansion-panels > li
    border-top: 1px solid rgba(#fff, .12) !important
</style>
